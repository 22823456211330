<template>
  <div>
    <label class="label-modal">{{ $t("fields.image") }}</label>
    <div v-if="!isImagem">
      <input
        accept="image/*"
        hidden="hidden"
        name="file"
        id="file-1"
        class="inputfile inputfile-1"
        type="file"
        ref="file"
        @change="onFileChange"
      />
      <label for="file-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="17"
          viewBox="0 0 20 17"
        >
          <path
            d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
          />
        </svg>
      </label>
    </div>
    <div v-else>
      <v-flex xs12 sm6 md12>
        <v-btn
          class="removeImageBtn"
          @click.native="removeImage"
          text
          icon
          color="red lighten-1"
        >
          <v-icon color="red lighten-1">cancel</v-icon>
        </v-btn>
        <v-img
          v-if="file"
          id="img-upload"
          :src="this.imagem"
          aspect-ratio="1.7"
          contain
          loading="lazy"
        ></v-img>
        <v-img
          v-else
          id="img-upload"
          :src="$imagemsUrl + '/' + MyObject.id_ordem + '/' + MyObject.imagem"
          aspect-ratio="1.7"
          contain
        ></v-img>
      </v-flex>
    </div>
  </div>
</template>
<script>
import CRUD from "@/mixins/_CRUD"
export default {
  mixins: [CRUD],
  props: {
    MyObject: Object,
    Field: String
  },
  data: () => ({
    file: "",
    imagem: "",
    isImagem: false
  }),
  watch: {
    MyObject(val) {
      this.isImagem = !(val.imagem == null)
    }
  },
  created() {
    this.$eventBus.$on("ClearImage", () => {
      this.imagem = this.file = ""

      this.isImagem = false
    })
  },
  mounted() {
    setTimeout(() => {
      this.isImagem = !(this.MyObject.imagem == null)
    }, 110)
  },

  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      this.file = this.$refs.file.files[0]

      this.createImage(files[0])
    },
    createImage(file) {
      var reader = new FileReader()

      reader.onload = e => {
        this.imagem = e.target.result
        setTimeout(() => {
          this.MyObject.imagem = e.target.result
          this.MyObject.changeImage = true
          this.isImagem = true
        }, 100)
      }
      reader.readAsDataURL(file)
    },
    removeImage: function(e) {
      this.imagem = this.file = ""
      setTimeout(() => {
        this.MyObject.imagem = null
        this.MyObject.changeImage = true
        this.isImagem = false
      }, 100)
    }
  }
}
</script>
